import { Logger } from './logger';

const authFormId = 'logon-form';
const usernameInputId = 'username';
const passwordInputId = 'password';
const selectLangId = 'lang';
// @WARN i.ablov don't exist now on auth form!
const selectThemeId = '_login-theme-val';

const logonFormId = 'hiddenform';
const generalValidationElementId = 'general-validation';

const badpasswordBackendError = 'badpassword';
const recaptchaBackendError = 'recaptcha';
// @TODO i.ablov add right error key
const socialBackendError = 'DONT_KNOW_WHAT_KEY';

// @WARN set by BE in xsl file! Contain information about page, look into .xsl file for details
// const pageInfo;

/**
 * Class for handle login page actions
 */
export class LoginForm {
  constructor() {
    this.init();
  }

  init() {
    /** <form> element */
    this.form = document.getElementById(authFormId);
    /** <ispui-input> element */
    this.inputUsername = document.getElementById(usernameInputId);
    /** <ispui-password> element */
    this.inputPassword = document.getElementById(passwordInputId);
    /** <ispui-select> element */
    this.selectLang = document.getElementById(selectLangId);

    // don't exist for now
    // this.inputTheme = document.getElementById(selectThemeId);

    this.localValidators = new Map([
      [this.inputUsername, [this.checkRequired]],
      [this.inputPassword, [this.checkRequired]],
    ]);

    this.appendFormSubmittingHandler();

    this.appendCheckValidationOnBlur(this.inputUsername);
    this.appendCheckValidationOnBlur(this.inputPassword);

    this.appendSubmitOnSelectChange(this.selectLang);

    this.handleBackendError();
  }

  handleBadPasswordError() {
    Logger.log('Badpassword backend error detected');
    // @WARN setTimeout because we need to wait until layout renders properly, so error tooltip will get right position
    setTimeout(() => {
      this.setFieldError(this.inputUsername, {
        name: 'backendError',
        msg: pageInfo.error.msg,
      });
      this.setFieldError(this.inputPassword, {
        name: 'backendError',
      });
    });

    const backendErrorReset = () => {
      Logger.log('Field changed, badpassword backed error validation removed');

      this.setFieldError(this.inputUsername, false);
      this.setFieldError(this.inputPassword, false);

      this.inputUsername.removeEventListener('customChange', backendErrorReset);
      this.inputPassword.removeEventListener('customChange', backendErrorReset);
    };

    this.inputUsername.addEventListener('customChange', backendErrorReset);
    this.inputPassword.addEventListener('customChange', backendErrorReset);
  }

  setGeneralValidationMsg(msg) {
    const generalErrorElement = document.getElementById(
      generalValidationElementId,
    );
    if (generalErrorElement) {
      generalErrorElement.innerText = msg;
    }
  }

  /**
   * Handler backend error
   */
  handleBackendError() {
    if (typeof pageInfo !== 'undefined' && pageInfo.error?.object) {
      switch (pageInfo.error.object) {
        case badpasswordBackendError:
          this.handleBadPasswordError();
          break;
        default:
          this.setGeneralValidationMsg(pageInfo.error.msg);
          break;
      }
    }
  }

  /**
   * Handler form submitting
   */
  appendFormSubmittingHandler() {
    this.form.addEventListener('submit', (e) => {
      e = e || window.event;

      Logger.group('Form submiting');
      const hasErrors = this.isThereAnyErrorsInField(this.localValidators);
      Logger.log(
        hasErrors
          ? 'Form submiting prevented: form have validation error'
          : 'validation passed, sending...',
      );
      Logger.groupEnd();

      if (hasErrors) {
        e.preventDefault();
      }
    });
  }

  /**
   * Add field blur handling, updating validation state on blur
   *
   * @param {ISPUIInputElement} field - ispui-input field
   */
  appendCheckValidationOnBlur(field) {
    // @WARN works only for ispui-input elements!
    field.addEventListener('customBlur', () => {
      Logger.group(`Validate on blur '${field.id}'`);
      Logger.log('field: ', field);

      const localValidators = this.localValidators.get(field) || [];
      for (const validator of localValidators) {
        const validationError = validator({ value: field.value, id: field.id });

        if (validationError) {
          Logger.log('validation error: ', validationError);
          this.setFieldError(field, validationError);
          Logger.groupEnd();
          return;
        }
      }
      Logger.log('validation passed');
      this.setFieldError(field, false);
      Logger.groupEnd();
    });
  }

  /**
   * Add select change handling, submiting form on change. Some kind of 'setValues' mechanism
   *
   * @param {ISPUISelectElement} field - ispui-select field
   */
  appendSubmitOnSelectChange(field) {
    // @WARN works only with ispui-select elements!
    field.addEventListener('change', (e) => {
      Logger.group(`Select value changed: '${field.id}'`);
      Logger.log(`new value: (${e.detail.value})`);
      if (pageInfo.lang === e.detail.value) {
        Logger.log('select value abandoned, due to same value, as in pageInfo');
        Logger.groupEnd();
        return;
      }
      Logger.groupEnd();
      this.selectHandler(field, e);
    });
  }

  /**
   * Check if field value is not empty
   *
   * @param { value: string, id: string } param - validator params
   */
  checkRequired({ value }) {
    if (value === '') {
      // @WARN use no msg, in order to not display tooltip 'This field is required'
      return {
        name: 'requiredError',
      };
    } else {
      return false;
    }
  }

  /**
   * Set field tooltip msg and visibility
   *
   * @param field - field element like <ispui-input>
   * @param visibility - tooltip visibility state
   * @param errorObject - error object with msg for tooltip
   */
  setTooltipVisibility(field, visibility, errorObject) {
    const tooltip =
      field.parentElement.getElementsByTagName('ispui-tooltip')[0];

    if (!tooltip) {
      return;
    }

    if (visibility && errorObject.msg) {
      Logger.log(`Set tooltip visibility of '${field.id}' to true`);
      tooltip.titleText = errorObject.msg;
      tooltip.toggle(true);
    } else {
      Logger.log(`Set tooltip visibility of '${field.id}' to false`);
      tooltip.titleText = '';
      tooltip.toggle(false);
    }
  }

  /**
   * Set field error state
   *
   * @param field - field element like <ispui-input>
   * @param errorObject - error object
   */
  setFieldError(field, errorObject) {
    const hasError = Boolean(errorObject);
    Logger.group(
      `Set validaiton state of '${field.id}' to '${
        hasError ? 'invalid' : 'ok'
      }'`,
    );
    if (hasError) {
      field.setAttribute('invalid', true);
      // @HACK set validation icon only on username field
      if (field.id === usernameInputId) {
        field.setAttribute('validation-icon', true);
      }

      this.setTooltipVisibility(field, true, errorObject);
    } else {
      field.removeAttribute('invalid');
      field.removeAttribute('validation-icon');

      this.setTooltipVisibility(field, false);
    }
    Logger.groupEnd();
  }

  /**
   * Check field for some errors and appending 'invalid' state and toggle error tooltip if needed
   *
   * @param {Map<ISPUIInputElement, Validator[]>} fieldAndValidatorsMap - map of fields and related validators
   */
  isThereAnyErrorsInField(fieldAndValidatorsMap) {
    let someFieldHasError = false;

    Logger.group('Check form fields validation');
    Array.from(fieldAndValidatorsMap.entries()).forEach(
      ([field, validators]) => {
        Logger.group(`Validate '${field.id}'`);
        Logger.log('field: ', field);
        for (const validator of validators) {
          const validationError = validator({
            value: field.value,
            id: field.id,
          });

          if (validationError) {
            Logger.log('validation error: ', validationError);
            Logger.groupEnd();
            this.setFieldError(field, validationError);
            someFieldHasError = true;

            return;
          }
        }
        Logger.log('validation passed');
        Logger.groupEnd();
        this.setFieldError(field, false);
      },
    );
    Logger.groupEnd();

    return someFieldHasError;
  }

  /**
   * Hangle select change
   */
  selectHandler(field) {
    const id = field.id;
    if (id !== selectThemeId && id !== selectLangId) {
      return;
    }

    Logger.log(`Select value handling: '${field.id}', submiting form`);

    const hiddenSelectInput = document.getElementById(`${id}-hidden`);
    hiddenSelectInput.value = field.value;

    const params = this.getFormParamsForLogon();
    const form = this.createLogonHiddenForm(params);

    Logger.group(`Select value handling: '${field.id}'`);
    Logger.log('params: ', params);
    Logger.groupEnd();

    const wrapper = document.getElementById(logonFormId);
    wrapper.appendChild(form);
    form.submit();
  }

  /**
   * Get form params object
   */
  getFormParamsForLogon() {
    const lang = this.selectLang.value;
    const theme = this.inputTheme ? this.inputTheme.value : undefined;
    const passwd = this.inputPassword.value;
    const username = this.inputUsername.value;
    return {
      func: 'logon',
      lang: lang,
      theme: theme,
      username: username,
      pwd: passwd,
    };
  }

  /**
   * Create <form> element with appended <input> fields with values from 'params' argument
   *
   * @param {Record<string, string | number>} params - form params
   */
  createLogonHiddenForm(params) {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', pageInfo.url);
    /* jslint forin:true */
    for (const keyVar in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', keyVar);
      input.setAttribute('value', params[keyVar]);
      form.appendChild(input);
    }

    return form;
  }
}
